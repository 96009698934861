<template>
  <div class="roomLogDetails">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>日志详情</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form ref="form" label-width="80px">
      <el-form-item label="酒店名称">
        <span>{{ roomLog.hotelName }}</span>
      </el-form-item>
      <el-form-item label="客房名称">
        <span>{{ roomLog.roomName }}</span>
      </el-form-item>
      <el-form-item label="修改人">
        <span>{{ roomLog.masterName }}</span>
      </el-form-item>
      <el-form-item label="修改字段">
        <span>{{ roomLog.strType }}</span>
      </el-form-item>
      <el-form-item label="修改时间">
        <span>{{ roomLog.hotelAddTime }}</span>
      </el-form-item>
      <el-form-item label="修改前内容">
        <span>{{ roomLog.curContent }}</span>
      </el-form-item>
      <el-form-item label="修改后内容">
        <span>{{ roomLog.endContent }}</span>
      </el-form-item>
      <el-form-item label="修改类型">
        <span>{{ roomLog.hotelLogType }}</span>
      </el-form-item>
    </el-form>
    <el-button type="primary" @click="$router.go(-1)">返回</el-button>
  </div>
</template>
<script>
import { roomLogDetails } from "../../api/roomLog";
export default {
  name: "roomLogDetails",
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      roomLog: {},
    };
  },
  created() {
    this.getroomLogDetails();
  },
  methods: {
    async getroomLogDetails() {
      const { data } = await roomLogDetails({
        roomLogId: Number(this.id),
      });
      console.log(data.data[0]);
      this.roomLog = data.data[0];
    },
  },
};
</script>
<style lang="less" scoped>
.roomLogDetails {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
}
</style>